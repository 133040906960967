.login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &:before {    
    content: "";
    background-image: url(~assets/img/Logo-simbolo-GUAITIL.png);
    background-size: auto 15%;
    background-position: right top;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.2;
  }
  .card{
    overflow: hidden;
    border-radius: 7px;
    width: 400px;
    background: transparent;
    padding-bottom: 20px;
    .card-header {
      background-image: url(~assets/img/bgLogin.png);
      background-repeat: no-repeat;
      color: white;
      position: relative;
      padding: 30px;
      h4 {
        margin: 0;
      }
      h2 {
        margin-top: 0;
      }
      &:before {
        display: block;
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .77;
        background: linear-gradient(to right, #9e7757 0%, #9c9c9c 100%);
        background-position: bottom bottom;
      }
      h2, h4, small {
        position: relative;
        z-index: 2;
      }
    }
    .card-body {
      background-color: rgba(255,255,255, 0.3);
    }
  }
}