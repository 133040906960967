/*           Font Smoothing      */
body,
h1, .h1, 
h2, .h2, 
h3, .h3, 
h4, .h4, 
h5, .h5, 
h6, .h6, 
p, 
.navbar, 
.brand, 
.btn-simple,
.alert, 
a, 
.td-name, 
td,
button.close{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto","Helvetica Neue",Arial,sans-serif;
    font-weight: $font-weight-normal;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4{
    font-weight: $font-weight-light;
    // margin: $margin-large-vertical 0 $margin-base-vertical;
}

h1, .h1 {
    font-size: $h1-font-size;
}
h2, .h2{
    font-size: $h2-font-size;
}
h3, .h3{
    font-size: $h3-font-size;
    margin: 20px 0 10px;
}
h4, .h4{
    font-size: $h4-font-size;
    line-height: 30px;
}
h5, .h5 {
    font-size: $h5-font-size;
    margin-bottom: 15px;
}
h6, .h6{
    font-size: $h6-font-size;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
}

h1 small, h2 small, h3 small, h4 small, h5 small, h6 small, .h1 small, .h2 small, .h3 small, .h4 small, .h5 small, .h6 small, h1 .small, h2 .small, h3 .small, h4 .small, h5 .small, h6 .small, .h1 .small, .h2 .small, .h3 .small, .h4 .small, .h5 .small, .h6 .small {
    color: $dark-gray;
    font-weight: $font-weight-light;
    // line-height: $line-height-general;
}

h1 small, h2 small, h3 small, h1 .small, h2 .small, h3 .small {
    font-size: 60%;
}

h1 .subtitle{
    display: block;
    // margin: 0 0 $margin-large-vertical;   
}
