@mixin opacity($opacity) {
  opacity: $opacity;
  // IE8 filter
  $opacity-ie: ($opacity * 100);
  filter: #{alpha(opacity=$opacity-ie)};
}

@mixin vertical-align {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin icon-gradient ($top-color, $bottom-color){
  background: $top-color;
  background: -moz-linear-gradient(top,  $top-color 0%, $bottom-color 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$top-color), color-stop(100%,$bottom-color));
  background: -webkit-linear-gradient(top,  $top-color 0%,$bottom-color 100%);
  background: -o-linear-gradient(top,  $top-color 0%,$bottom-color 100%);
  background: -ms-linear-gradient(top,  $top-color 0%,$bottom-color 100%);
  background: linear-gradient(to bottom,  $top-color 0%,$bottom-color 100%);
  background-size: 150% 150%;
}

@mixin transform-translate-3d-0($value){
  -webkit-transform: translate3d(0, $value, 0) !important;
  -moz-transform: translate3d(0, $value, 0) !important;
  -o-transform: translate3d(0, $value, 0) !important;
  -ms-transform: translate3d(0, $value, 0) !important;
  transform: translate3d(0, $value, 0) !important;
}

@mixin transform-translate-y-dropdown($value){
  -webkit-transform:  translate3d(0, $value, 0) !important;
     -moz-transform: translate3d(0, $value, 0) !important;
     -o-transform: translate3d(0, $value, 0) !important;
     -ms-transform: translate3d(0, $value, 0) !important;
     transform: translate3d(0, $value, 0) !important;
}

@mixin transform-scale($value){
  -webkit-transform: scale($value);
     -moz-transform: scale($value);
     -o-transform: scale($value);
     -ms-transform: scale($value);
     transform: scale($value);
}
@mixin transform-origin($coordinates){
  -webkit-transform-origin: $coordinates;
    -moz-transform-origin: $coordinates;
    -o-transform-origin: $coordinates;
    -ms-transform-origin: $coordinates;
    transform-origin: $coordinates;
}

@mixin transform-translate-3d($value){
  -webkit-transform: translate3d($value, 0, 0);
  -moz-transform: translate3d($value, 0, 0);
  -o-transform: translate3d($value, 0, 0);
  -ms-transform: translate3d($value, 0, 0);
  transform: translate3d($value, 0, 0) !important;
}

@mixin transform-translate-x($value){
  -webkit-transform:  translate3d($value, 0, 0);
     -moz-transform: translate3d($value, 0, 0);
     -o-transform: translate3d($value, 0, 0);
     -ms-transform: translate3d($value, 0, 0);
     transform: translate3d($value, 0, 0);
}

@mixin bar-animation($type){
  -webkit-animation: $type 500ms linear 0s;
  -moz-animation: $type 500ms linear 0s;
  animation: $type 500ms 0s;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

@mixin topbar-x-rotation(){
  @keyframes topbar-x {
    0% {top: 0px; transform: rotate(0deg); }
    45% {top: 6px; transform: rotate(145deg); }
    75% {transform: rotate(130deg); }
    100% {transform: rotate(135deg); }
  }
  @-webkit-keyframes topbar-x {
    0% {top: 0px; -webkit-transform: rotate(0deg); }
    45% {top: 6px; -webkit-transform: rotate(145deg); }
    75% {-webkit-transform: rotate(130deg); }
    100% { -webkit-transform: rotate(135deg); }
  }
  @-moz-keyframes topbar-x {
    0% {top: 0px; -moz-transform: rotate(0deg); }
    45% {top: 6px; -moz-transform: rotate(145deg); }
    75% {-moz-transform: rotate(130deg); }
    100% { -moz-transform: rotate(135deg); }
  }
}

@mixin topbar-back-rotation(){
  @keyframes topbar-back {
    0% { top: 6px; transform: rotate(135deg); }
    45% { transform: rotate(-10deg); }
    75% { transform: rotate(5deg); }
    100% { top: 0px; transform: rotate(0); }
  }

  @-webkit-keyframes topbar-back {
    0% { top: 6px; -webkit-transform: rotate(135deg); }
    45% { -webkit-transform: rotate(-10deg); }
    75% { -webkit-transform: rotate(5deg); }
    100% { top: 0px; -webkit-transform: rotate(0); }
  }

  @-moz-keyframes topbar-back {
    0% { top: 6px; -moz-transform: rotate(135deg); }
    45% { -moz-transform: rotate(-10deg); }
    75% { -moz-transform: rotate(5deg); }
    100% { top: 0px; -moz-transform: rotate(0); }
  }
}

@mixin bottombar-x-rotation(){
  @keyframes bottombar-x {
    0% {bottom: 0px; transform: rotate(0deg);}
    45% {bottom: 6px; transform: rotate(-145deg);}
    75% {transform: rotate(-130deg);}
    100% {transform: rotate(-135deg);}
  }
  @-webkit-keyframes bottombar-x {
    0% {bottom: 0px; -webkit-transform: rotate(0deg);}
    45% {bottom: 6px; -webkit-transform: rotate(-145deg);}
    75% {-webkit-transform: rotate(-130deg);}
    100% {-webkit-transform: rotate(-135deg);}
  }
  @-moz-keyframes bottombar-x {
    0% {bottom: 0px; -moz-transform: rotate(0deg);}
    45% {bottom: 6px; -moz-transform: rotate(-145deg);}
    75% {-moz-transform: rotate(-130deg);}
    100% {-moz-transform: rotate(-135deg);}
  }
}

@mixin bottombar-back-rotation{
  @keyframes bottombar-back {
    0% { bottom: 6px;transform: rotate(-135deg);}
    45% { transform: rotate(10deg);}
    75% { transform: rotate(-5deg);}
    100% { bottom: 0px;transform: rotate(0);}
  }
  @-webkit-keyframes bottombar-back {
    0% {bottom: 6px;-webkit-transform: rotate(-135deg);}
    45% {-webkit-transform: rotate(10deg);}
    75% {-webkit-transform: rotate(-5deg);}
    100% {bottom: 0px;-webkit-transform: rotate(0);}
  }
  @-moz-keyframes bottombar-back {
    0% {bottom: 6px;-moz-transform: rotate(-135deg);}
    45% {-moz-transform: rotate(10deg);}
    75% {-moz-transform: rotate(-5deg);}
    100% {bottom: 0px;-moz-transform: rotate(0);}
  }

}