.app-loader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: visible;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px 0 0 -75px;
}
.app-loader svg { display: block; }
.app-loader path { fill-opacity: 0; }
.app-loader-animation {
  height: 150px;
  -webkit-animation: pt-app-loader-animation 1s cubic-bezier(.1, .2, .4, .1) infinite;
  animation: pt-app-loader-animation 1s cubic-bezier(.1, .2, .4, .1) infinite;
}
.app-loader-track { stroke: rgba(255, 82, 33, 0.2); }
.app-loader-head {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transition: stroke-dashoffset .2s cubic-bezier(.4, 1, .75, .9);
  transition: stroke-dashoffset .2s cubic-bezier(.4, 1, .75, .9);
  stroke: #ff5221;
  stroke-linecap: round;
}
@-webkit-keyframes pt-app-loader-animation {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

@keyframes pt-app-loader-animation {
  from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }
  to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
  }
}

/* Logo */
.app-loader-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -55px 0 0 -60px;
  width: 120px;
  height: 120px;
  background-image: url(~assets/img/Logo-simbolo-GUAITIL.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}