@import "bootstrap/scss/functions";
@import "variables";
@import "bootstrap/scss/bootstrap";

@import "mixins";
@import "typography";
@import "sidebar-and-main-panel";
@import "tables";
@import "responsive";
@import "icons";
/*     General overwrite     */
body,
.wrapper{
    min-height: 100vh;
    position: relative;
}
.wrapper{
    position: relative;
    top: 0;
    height: 100vh;
}
a{
  color: $info;

  &:hover, &:focus{
     color: $cyan-300;
     text-decoration: none;
  }
}


.footer{
  background-color: $white;
  .footer-menu{
      height: 41px;
  }
  nav > ul{
    list-style: none;
    margin: 0;
    padding: 0;
    font-weight: normal;
    a:not(.btn){
      color: $dark-gray;
      display: block;
      margin-bottom: 3px;
      &:hover,
      &:focus{
          color: $default-states-color;
      }
    }
  }
  .copyright{
    color: $default-states-color;
    padding: 10px 15px;
    margin: 10px 3px;
    line-height: 20px;
    font-size: $font-size-base;
  }
}

// .close {
//   float: right;
//   line-height: 1;
//   opacity: .5;
// }

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

// stylelint-disable-next-line selector-no-qualifying-type
button.close {
  @extend .btn-close;
  span { display: none; }
  &.rbt-close {
    margin-top: 0;
    &.rbt-token-remove-button {
      font-size: 13px;
      top: unset;
      bottom: unset;
    }
  }
}
